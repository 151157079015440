import React from 'react';
import { Button,  Divider, Box, Modal, Card, CardHeader, Stack, IconButton,  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { handleDownload } from './downloadFile';
import pdf from '../../assets/img/pdf.png'
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import ModalCss from "../../styles/modal.module.scss";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { makeStyles } from "@mui/styles"; // Changed import to use curly braces for makeStyles
import { formatDateString } from '../../utils/dateFormater';

const useStyles = makeStyles((theme) => ({
    bgLightGray: {
      backgroundColor: "rgba(213, 222, 229, 1)",
    },
    borderRadius10: {
      borderRadius: "10px",
    },
    p10: {
      padding: "10px",
    },
    p20: {
      padding: "20px",
    },
    buttonsStyle: {
      backgroundColor: "#D7EDFE",
    },
    fullWidth: {
      width: "100%",
    },
  }));
export default function ViewUploadedQuotationModal({ openQuotation, handleCloseQuotation , quotation}) {
    const classes = useStyles();
    const closeTheModal = () => {
        handleCloseQuotation();
    }
    return (
        <Modal open={openQuotation} onClose={closeTheModal} className={ModalCss.customModal}>
            <Card className={`modal ${ModalCss.customModal_md}`}>
                <CardHeader sx={{ textAlign: "center" }} title="Uploaded Quotation" />
                {/* <Typography component="div" sx={{ textAlign: "center", marginTop: 2, marginBottom: 2 }}>
                    You can view your uploaded Quotation here
                </Typography> */}
                <Divider sx={{ width: '100%', mt: 1, mb: 2 }} />
                <Box
                    component="div"
                    sx={{
                        maxWidth: "800px",  // Adjusted max-width for better fit
                        margin: "0 auto",
                        textAlign: "center",
                        padding: "20px",
                    }}
                >
                    <Stack alignItems="flex-end" position={"absolute"} right={5} top={5}>
                        <IconButton onClick={closeTheModal}>
                            <HighlightOffIcon />
                        </IconButton>
                    </Stack>
                    <Stack
                        justifyContent="center"
                        direction="column"
                        gap={2}
                        alignItems="center"
                        fontSize={"18px"}
                        boxSizing={"border-box"}
                        component={"div"}
                    >
                        <TableContainer component={Paper} sx={{ maxWidth: "100%", marginTop: 2 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center'>File</TableCell>
                                        <TableCell align='center'>Created Date</TableCell>
                                        <TableCell align='center'>Dealer's Response</TableCell>
                                        <TableCell align='center'>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {quotation.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell align='center'>
                                                <img width={40} height={40} src={pdf} style={{ marginTop: "10px", marginBottom: "10px" }} alt="PDF File" />
                                            </TableCell>
                                            <TableCell align='center'>{formatDateString(item.created_at)}</TableCell>
                                            <TableCell align='center'>{item?.dealer_approval === true ? "Approved" : "Pending"}</TableCell>
                                            <TableCell align='center'>
                                                <IconButton onClick={() => handleDownload(new Date() + 'quotation', item.quotation)}>
                                                    <DownloadForOfflineRoundedIcon />
                                                </IconButton>
                                                <IconButton onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${item.quotation}`)}>
                                                    <FullscreenIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Stack>
                </Box>
            </Card>
        </Modal>
        
    );
}