
import React,{useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Grid, Stack, Typography } from "@mui/material";
import InputDrawing from "./InputDrawing";
import DesignEstimate from "./DesignEstimate";
import SchematicLayout from "./SchematicLayout";
import FeedbackReceived from "./FeedbackRecived";
import RevisedLayout from "./RevisedLayout";
import Gfc from "./Gfc";
import Boxlayout from "./Boxlayout";
import Body from "../../components/Body";
import { useSelector } from "react-redux";
import GfcRevised from "./GfcRevised";

export default function Layout() {  
  const navigate = useNavigate();
  const inputDrawingData = useSelector((state) => state?.inputDrawingReducer?.result?.results?.data);
  const id = localStorage.getItem("selectedOutletIdToView")
  const inputDrawing = inputDrawingData.filter(feedback => feedback?.dealor_outlet_id === id)
  const [isValidOutlet, setIsValidOutlet] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1)
  useEffect(() => {
    const outletId = localStorage.getItem("selectedOutletIdToView");
    if (outletId === null) {
      navigate("/layout");
    } else {
      setIsValidOutlet(true);
    }
  }, [navigate]);
  
  if (!isValidOutlet) {
    return null;
  }

  return (
 
    <Body>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Stack
        direction="row"
        spacing={10}
        sx={{ my: 2 }}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Stack direction="row" alignItems="center">
          <svg
            width="28"
            height="20"
            viewBox="0 0 28 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.9878 16.4092C13.4745 16.4092 12.9728 16.257 12.546 15.9718C12.1192 15.6867 11.7866 15.2813 11.5901 14.8071C11.3937 14.3329 11.3423 13.8111 11.4424 13.3077C11.5426 12.8042 11.7898 12.3418 12.1527 11.9789C12.5157 11.6159 12.9781 11.3688 13.4815 11.2686C13.9849 11.1685 14.5067 11.2199 14.9809 11.4163C15.4552 11.6127 15.8605 11.9454 16.1456 12.3721C16.4308 12.7989 16.583 13.3007 16.583 13.814C16.5823 14.502 16.3086 15.1617 15.8221 15.6483C15.3355 16.1348 14.6759 16.4085 13.9878 16.4092ZM13.9878 12.2188C13.6723 12.2188 13.3639 12.3123 13.1015 12.4876C12.8392 12.6629 12.6347 12.912 12.514 13.2035C12.3933 13.495 12.3617 13.8158 12.4232 14.1252C12.4848 14.4346 12.6367 14.7189 12.8598 14.942C13.0829 15.1651 13.3672 15.317 13.6766 15.3785C13.986 15.4401 14.3068 15.4085 14.5983 15.2878C14.8898 15.167 15.1389 14.9626 15.3142 14.7002C15.4895 14.4379 15.583 14.1295 15.583 13.814C15.5825 13.3911 15.4142 12.9856 15.1152 12.6866C14.8161 12.3875 14.4107 12.2193 13.9878 12.2188Z"
              fill="#05141F"
            />
            <path
              d="M13.979 4.73828C13.9133 4.73831 13.8483 4.7254 13.7876 4.70029C13.7269 4.67518 13.6718 4.63835 13.6254 4.59191C13.5789 4.54547 13.5421 4.49034 13.517 4.42966C13.4919 4.36898 13.479 4.30395 13.479 4.23828V0.578125C13.479 0.445517 13.5317 0.31834 13.6255 0.224572C13.7192 0.130804 13.8464 0.078125 13.979 0.078125C14.1116 0.078125 14.2388 0.130804 14.3326 0.224572C14.4263 0.31834 14.479 0.445517 14.479 0.578125V4.23828C14.479 4.30395 14.4661 4.36898 14.441 4.42966C14.4159 4.49034 14.3791 4.54548 14.3326 4.59191C14.2862 4.63835 14.2311 4.67518 14.1704 4.70029C14.1097 4.72541 14.0447 4.73831 13.979 4.73828Z"
              fill="#05141F"
            />
            <path
              d="M26.2852 11.0234H23.5708C23.4382 11.0234 23.311 10.9708 23.2172 10.877C23.1235 10.7832 23.0708 10.656 23.0708 10.5234C23.0708 10.3908 23.1235 10.2637 23.2172 10.1699C23.311 10.0761 23.4382 10.0234 23.5708 10.0234H26.2852C26.4178 10.0234 26.5449 10.0761 26.6387 10.1699C26.7325 10.2637 26.7852 10.3908 26.7852 10.5234C26.7852 10.656 26.7325 10.7832 26.6387 10.877C26.5449 10.9708 26.4178 11.0234 26.2852 11.0234Z"
              fill="#05141F"
            />
            <path
              d="M4.41602 11.0234H1.68945C1.55684 11.0234 1.42967 10.9708 1.3359 10.877C1.24213 10.7832 1.18945 10.656 1.18945 10.5234C1.18945 10.3908 1.24213 10.2637 1.3359 10.1699C1.42967 10.0761 1.55684 10.0234 1.68945 10.0234H4.41602C4.54863 10.0234 4.67581 10.0761 4.76958 10.1699C4.86334 10.2637 4.91602 10.3908 4.91602 10.5234C4.91602 10.656 4.86334 10.7832 4.76958 10.877C4.67581 10.9708 4.54863 11.0234 4.41602 11.0234Z"
              fill="#05141F"
            />
            <path
              d="M13.0757 12.4299C12.9764 12.4299 12.8793 12.4004 12.7969 12.345C12.7145 12.2896 12.6504 12.2109 12.6128 12.1189L10.6885 7.40699C10.64 7.28452 10.6417 7.14784 10.6933 7.02665C10.745 6.90545 10.8424 6.80953 10.9643 6.75974C11.0863 6.70995 11.223 6.71031 11.3447 6.76074C11.4664 6.81117 11.5633 6.90759 11.6143 7.02905L13.5386 11.741C13.5696 11.8169 13.5815 11.8993 13.5731 11.9809C13.5648 12.0625 13.5365 12.1407 13.4908 12.2088C13.445 12.2769 13.3832 12.3327 13.3109 12.3712C13.2385 12.4098 13.1577 12.4299 13.0757 12.4299Z"
              fill="#05141F"
            />
            <path
              d="M24.5749 19.9267H3.40012C3.21848 19.9275 3.04 19.8792 2.88351 19.787C2.72701 19.6948 2.59831 19.5621 2.51096 19.4028C1.42554 17.412 0.876652 15.1731 0.918212 12.906C0.959772 10.639 1.59035 8.42167 2.74801 6.472C3.90566 4.52233 5.55058 2.90733 7.52116 1.78564C9.49175 0.663956 11.7202 0.0741703 13.9877 0.0742188C16.2552 0.0742672 18.4836 0.664148 20.4542 1.78592C22.4247 2.90769 24.0695 4.52276 25.2271 6.47248C26.3847 8.4222 27.0152 10.6395 27.0566 12.9066C27.0981 15.1737 26.5491 17.4126 25.4636 19.4033C25.3761 19.5623 25.2475 19.6949 25.0911 19.787C24.9347 19.8791 24.7564 19.9274 24.5749 19.9267ZM13.9875 1.06981C11.8933 1.07038 9.83527 1.61559 8.01545 2.65191C6.19564 3.68823 4.67663 5.18003 3.60758 6.98081C2.53854 8.7816 1.95621 10.8295 1.91778 12.9233C1.87936 15.0172 2.38615 17.085 3.3884 18.9238L24.5749 18.9267C24.577 18.927 24.5791 18.9268 24.5811 18.9262C24.5831 18.9256 24.585 18.9247 24.5866 18.9233C25.5887 17.0845 26.0953 15.0167 26.0568 12.923C26.0182 10.8293 25.4359 8.78152 24.3669 6.98084C23.2979 5.18016 21.7789 3.68843 19.9592 2.65211C18.1395 1.61579 16.0816 1.07051 13.9875 1.06981Z"
              fill="#05141F"
            />
          </svg>
          <Typography
            color={"#05141F"}
            fontSize={"17px"}
            fontWeight={400}
            marginInlineStart={"10px"}
            fontFamily={"'KiaSignatureRegular' !important"}
          >
            Layout Designing Plan
          </Typography>
        </Stack>
        <Button className="white-btn" onClick={() => navigate("/layout")} sx={{textTransform: 'none'}}> All Layout </Button>

      </Stack>  
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Card sx={{ borderRadius: "8px" }}>
          <Typography variant="h6" className="fs-17  fw-600">{inputDrawing[0]?.dealor_outlet_name}</Typography>
          <Grid container spacing={2}>

            <>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                {(() => {
                  switch (selectedTab) {
                    
                    case 1:
                      return <InputDrawing dealorOutletId={id}/>;
                    case 2:
                      return <DesignEstimate formID={inputDrawing[0].id}/>;
                    case 3:
                      return <SchematicLayout formID={inputDrawing[0].id} />;
                    case 4:
                      return <RevisedLayout formID={inputDrawing[0].id} setSelectedTab={setSelectedTab} />;
                    case 5:
                        return <Gfc formID={inputDrawing[0].id}/>;
                    case 6:
                        return <GfcRevised formID={inputDrawing[0].id} setSelectedTab={setSelectedTab} />;
                    case 7:
                      return <FeedbackReceived formID={inputDrawing[0].id}/>;
                    default:
                      return null;
                  }
                })()}
              </Grid>
            </>
            <>
            </>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={3} sx={{ padding: "16px !important" }}>
              <Boxlayout setSelectedTab={setSelectedTab} selectedTab={selectedTab}/>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Body>
  )}