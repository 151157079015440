import {
    Box,
    Button,
    Card,
    CardHeader,
    IconButton,
    Modal,
    Stack,
    Typography,
  } from "@mui/material";
  import React, { useRef, useState } from "react";
  import HighlightOffIcon from "@mui/icons-material/HighlightOff";
  import ModalCss from "../../styles/modal.module.scss";
  import { makeStyles } from "@mui/styles"; // Changed import to use curly braces for makeStyles
  import { useDispatch } from "react-redux";
  import TextSnippetIcon from "@mui/icons-material/TextSnippet";
  import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
  import { showToast } from "../../toast/toast";
import { sendFeedback } from "../../store/home/sendFeedback";
import { sendInvoice } from "../../store/home/sendInvoice";
import { getDesignEstimateApi } from "../../store/home/getDesignEstimate";

  
  const useStyles = makeStyles((theme) => ({
    bgLightGray: {
      backgroundColor: "rgba(213, 222, 229, 1)",
    },
    borderRadius10: {
      borderRadius: "10px",
    },
    p10: {
      padding: "10px",
    },
    p20: {
      padding: "20px",
    },
    buttonsStyle: {
      backgroundColor: "#D7EDFE",
    },
    fullWidth: {
      width: "100%",
    },
  }));
  
  const UploadInvoiceModal = ({ open, handleClose, estimateId, utrNo, formId }) => {
    const classes = useStyles();
    const [feedback, setFeedback] = useState("");
    const [invoiceError, setInvoiceError] = useState(false);
    const [invoiceFile, setInvoiceFile] = useState(null);
    const [fileError, setFileError] = useState("");
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const [fileState, setFileState] = useState();
  
    const handleBulkUpload = () => {
      inputRef.current.click();
    };
  
    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        const fileType = file.type;
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (fileType === 'application/pdf' && fileExtension === 'pdf') {
          setFileState(file);
          setFileError("");
        } else {
          setFileState(null);
          setFileError("Only PDF files are allowed.");
        }
      }
    };
  
    // const handleChangeFile = (event) => {
    //   const file = event.target.files[0];
    //   if (file) {
    //     setInvoiceFile(file);
    //   }
    // };
  
    const sendInvoiceToDealer = async () => {
      if (!fileState) {
        // Handle the error if no file is selected
        showToast("Please upload an invoice",2);
        return;
      }
  
      const formData = new FormData();
      formData.append("estimate_id", estimateId);
      // formData.append("utr_no", utrNo);
      // formData.append("feedback", feedback);
      formData.append("invoice", fileState);

      await dispatch(sendInvoice(formData))
          .then((data) => {
            showToast(data?.payload?.message, 1);
            const dataToSubmit = {
              form_id : formId
            }
            dispatch(getDesignEstimateApi(dataToSubmit))  
            handleClose()
            })
    
          .catch((error) => {
            console.error("Error editing email template:", error);
          });
    };

    const closeTheModal = () => {
        setInvoiceFile(null);
        setFileError("");
        setFileState("");
        handleClose();
    };
  
    return (
      <Modal open={open} onClose={() => closeTheModal()} className={ModalCss.customModal}>
        <Card className={`modal ${ModalCss.customModal_md}`}>
          <CardHeader sx={{ textAlign: "center" }} title="Upload Invoice" />
          <Typography component="div" sx={{ textAlign: "center", marginTop: 2, marginBottom: 2 }}>
            Please upload your invoice and send it to the dealer.
          </Typography>
          <Box
            component="div"
            sx={{
              maxWidth: "600px",
              margin: "50px auto",
              textAlign: "center",
            }}
          >
            <Stack alignItems="flex-end" position={"absolute"} right={5} top={5}>
              <IconButton onClick={() => closeTheModal()}>
                <HighlightOffIcon />
              </IconButton>
            </Stack>
            <Stack
              justifyContent="center"
              direction="column"
              gap={2}
              alignItems="center"
              fontSize={"18px"}
              boxSizing={"border-box"}
              component={"div"}
            >
              <Box
                component={"div"}
                className={`${classes.bgLightGray} ${classes.borderRadius10} ${classes.p20} ${classes.fullWidth}`}
                boxSizing={"border-box"}
              >
                <Typography
                  component={"div"}
                  sx={{
                    width: "100%",
                    fontSize: "15px",
                    color: "black",
                    lineHeight: 1.2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                    height: {
                      xs: "200px",
                      md: "200px",
                    },
                  }}
                >
                  {fileState ? (
                    <>
                      <TextSnippetIcon sx={{ fontSize: "100px" }} />
                      {fileState.name}
                    </>
                  ) : (
                    <>
                      <FileUploadOutlinedIcon sx={{ fontSize: "100px" }} />
                      Upload Invoice
                    </>
                  )}
                  <input
                    type="file"
                    ref={inputRef}
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />
                </Typography>
              </Box>
              {fileError && (
                <Typography
                  component={"div"}
                  sx={{ color: "red", fontSize: "14px", marginTop: 1 }}
                >
                  {fileError}
                </Typography>
              )}
              <Box
                component={"div"}
                width={"100%"}
                marginBlockStart={0.5}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={2}
              >
                <Button
                  variant="contained"
                  onClick={handleBulkUpload}
                //   sx={{
                //     fontSize: "18px",
                //     fontWeight: "500",
                //     lineHeight: 1.8,
                //     boxShadow: "none",
                //     backgroundColor: "#000 !important",
                //   }}
                  className="white-btn"
                >
                  Choose file
                </Button>
              </Box>
              <Box
                component={"div"}
                width={"100%"}
                marginBlockStart={2}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={2}
              >
                <Button
                  variant="contained"
                  onClick={sendInvoiceToDealer}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: 1.8,
                    boxShadow: "none",
                    backgroundColor: "#000 !important",
                  }}
                  className={classes.buttonsStyle}
                >
                  Send to Dealer
                </Button>
              </Box>
            </Stack>
          </Box>
        </Card>
      </Modal>
    );
  };
  
  export default UploadInvoiceModal;
  