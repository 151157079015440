import React , {useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, Typography } from "@mui/material";
import { getDesignEstimateApi } from '../../store/home/getDesignEstimate';
import { useDispatch, useSelector } from 'react-redux';
import { convertToDateFormatYearWithTwoDigits } from '../../utils/dateFormater';
import UploadInvoiceModal from '../reuseable/UploadInvoiceModal';
import Loader from '../reuseable/Loader';
import DialogModal from '../reuseable/DialogModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  

const DesignEstimate = ({formID}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);  // State for modal visibility
  const handleOpen = (id) => setOpen(true);
  const handleClose = () => setOpen(false);
  const {result : designEstimateData, loading : designEstimatetLoading} = useSelector((state) => state?.designEstimateReducer);

  const [estimateId, setEstimateId] = useState(null);
  const [utrNo, setUtrNo] = useState(null);
  const [paySlip, setPaySlip] = useState(null);
  const showUploadInvoiceModal = (estimateId, utrNo , paymentSlip) => {
    setEstimateId(estimateId)
    setUtrNo(utrNo)
    setPaySlip(paymentSlip)
    handleOpen();
  }

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);
  const [showType, setShowType] = useState("");
  const showConfirmModal = (estimateId, utrNo , paymentSlip, type) => {
    //show the modal here
    setEstimateId(estimateId)
    setUtrNo(utrNo)
    setPaySlip(paymentSlip)
    setShowType(type)
    setOpenConfirmModal(true);
  }

  const confirmApprovePayment = (estimateId, utrNo ) => {
      //API to approve payment
  }
  useEffect(() => {
    if(!formID) return
    const dataToSubmit = {
      form_id : formID
    }
    dispatch(getDesignEstimateApi(dataToSubmit))    
  },[dispatch, formID])

  if(designEstimatetLoading) return <Loader/>
  return (
      <>
        <Box>
         <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} >
             <Typography variant="h6" className="fs-17  fw-400">Design Estimate</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                <TableContainer component={Paper} sx={{borderRadius:"10px 10px 0 0"}}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{background:"#85949F !important"}}>Estimate Type</StyledTableCell>
                                <StyledTableCell sx={{background:"#85949F !important"}} align="center">Date</StyledTableCell>
                                <StyledTableCell sx={{background:"#85949F !important"}} align="center">Payment Status</StyledTableCell>
                                <StyledTableCell sx={{background:"#85949F !important"}} align="center">Payment Reciept</StyledTableCell>
                                <StyledTableCell sx={{background:"#85949F !important"}} align="center">Invoice</StyledTableCell>
                                {/* <StyledTableCell sx={{background:"#85949F !important"}} align="center">Action</StyledTableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {designEstimateData?.map((row) => (
                            <StyledTableRow key={row.estimate}>
                            <StyledTableCell component="th" scope="row">
                                Estimate type
                            </StyledTableCell>
                            <StyledTableCell align="center">{row.created_at ? convertToDateFormatYearWithTwoDigits(row.created_at) : "-"}</StyledTableCell>
                            <StyledTableCell align="center">
                            {row.utr_no ? (
                                row.payment_status === true ? (
                                    <Button className="doneBtn">Done</Button>
                                ) : (
                                    <Button variant='contained' title='click to verify' onClick={()=> showConfirmModal(row?.id , row?.utr_no, row?.payment_slip,"verify")}>Unverified</Button>
                                )
                            ) : (
                                <Button className="notdoneBtn">Not Done</Button>
                            )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              { row.utr_no ? (
                                <Button className="doneBtn" onClick={() => showConfirmModal(row.id,row?.utr_no, row?.payment_slip,"view")}>View</Button>
                              ):(
                                'No Data Found'
                              )}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                                {row.invoice && row.utr_no && row.payment_status ? (
                                  <Button className="doneBtn" onClick={() => showConfirmModal(row.id,row?.utr_no, row?.invoice,"invoice")} >View</Button>
                                ) : row.invoice === null && row.payment_status === true ? (
                                  <Button className="notdoneBtn" onClick={() => showUploadInvoiceModal(row.id, row.utr_no)}>Upload</Button>
                                ) : (
                                  <Button className='notdoneBtn'>Payment verification is pending</Button>
                                )}
                            </StyledTableCell>


                            {/* <StyledTableCell align="center">    
                            {row.invoice === "" ? (
                                <Button className="notdoneBtn" onClick={() => showUploadInvoiceModal(row.id,row?.utr_no)}>Upload</Button>
                            ) : (
                              'Payment is pending'
                            )
                              
                            }</StyledTableCell> */}
                            
                            {/* <StyledTableCell align="center">
                                <Button className="DownloadBtn" disabled>Download</Button>
                            </StyledTableCell> */}
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
         </Grid>
        </Box>
        <UploadInvoiceModal open={open} handleClose={handleClose} estimateId={estimateId} utrNo={utrNo} formId={formID}/>
        <DialogModal formID={formID} open={openConfirmModal} handleCloseConfirmModal={handleCloseConfirmModal} estimate_id={estimateId} utr_no={utrNo} utr_file={paySlip} type={showType} />
        </>
    );
  }
  export default DesignEstimate;