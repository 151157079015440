import * as yup from "yup";


export const loginSchema = yup.object({
  email: yup.string().email("Invalid email address").required("Email is Required"),
  password: yup.string().matches().required("Password is Required"),
  
})
.required();


export const cadUploadSchema = yup.object({
    cadFile: yup.mixed()
      .required("CAD file is required")
      .test("fileType", "Only DWG files are allowed", (value) => {
        // Check if value is an array and contains files
        if (value && value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i] && value[i].type !== "application/dwg" && !value[i].name.endsWith('.dwg')) {
              return false;
            }
          }
          return true;
        }
        return false;
      })
      .test("fileSize", "Each file should be less than 10MB", (value) => {
        // Check if value is an array and contains files
        if (value && value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i] && value[i].size > 10 * 1024 * 1024) {
              return false;
            }
          }
          return true;
        }
        return false;
      }),
  }).required();



export const pdfUploadSchema = yup.object({
  pdfFile: yup.mixed()
      .required("PDF file is required")
      .test("fileType", "Only PDF files are allowed", (value) => {
          // Check if value is an array and contains files
          if (value && value.length > 0) {
              for (let i = 0; i < value.length; i++) {
                  if (value[i] && value[i].type !== "application/pdf") {
                      return false;
                  }
              }
              return true;
          }
          return false;
      })
      .test("fileSize", "Each file should be less than 10MB", (value) => {
          // Check if value is an array and contains files
          if (value && value.length > 0) {
              for (let i = 0; i < value.length; i++) {
                  if (value[i] && value[i].size > 10 * 1024 * 1024) {
                      return false;
                  }
              }
              return true;
          }
          return false;
      }),
}).required();



export const schematicLayoutSchema = yup.object({
    pdfFile: yup.mixed()
      .required("PDF file is required")
      .test("fileType", "Only PDF files are allowed", (value) => {
        // Check if value is an array and contains files
        if (value && value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i] && value[i].type !== "application/pdf") {
              return false;
            }
          }
          return true;
        }
        return false;
      })
      .test("fileSize", "Each file should be less than 10MB", (value) => {
        // Check if value is an array and contains files
        if (value && value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i] && value[i].size > 10 * 1024 * 1024) {
              return false;
            }
          }
          return true;
        }
        return false;
      }),
    cadFile: yup.mixed()
      .required("CAD file is required")
      .test("fileType", "Only DWG files are allowed", (value) => {
        // Check if value is an array and contains files
        if (value && value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i] && value[i].type !== "application/dwg" && !value[i].name.endsWith('.dwg')) {
              return false;
            }
          }
          return true;
        }
        return false;
      })
      .test("fileSize", "Each file should be less than 10MB", (value) => {
        // Check if value is an array and contains files
        if (value && value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i] && value[i].size > 10 * 1024 * 1024) {
              return false;
            }
          }
          return true;
        }
        return false;
      }),
  }).required();

  export const GFCSchema = yup.object({
    pdfFile: yup.mixed()
      .required("PDF file is required")
      .test("fileType", "Only PDF files are allowed", (value) => {
        // Check if value is an array and contains files
        if (value && value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i] && value[i].type !== "application/pdf") {
              return false;
            }
          }
          return true;
        }
        return false;
      })
      .test("fileSize", "Each file should be less than 10MB", (value) => {
        // Check if value is an array and contains files
        if (value && value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i] && value[i].size > 10 * 1024 * 1024) {
              return false;
            }
          }
          return true;
        }
        return false;
      }),
    cadFile: yup.mixed()
      .required("CAD file is required")
      .test("fileType", "Only DWG files are allowed", (value) => {
        // Check if value is an array and contains files
        if (value && value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i] && value[i].type !== "application/dwg" && !value[i].name.endsWith('.dwg')) {
              return false;
            }
          }
          return true;
        }
        return false;
      })
      .test("fileSize", "Each file should be less than 10MB", (value) => {
        // Check if value is an array and contains files
        if (value && value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i] && value[i].size > 10 * 1024 * 1024) {
              return false;
            }
          }
          return true;
        }
        return false;
      }),
  }).required();


export const addInventorySchema = yup.object().shape({
  supplier_company_id: yup.lazy((value, { parent }) =>
    parent.supplier_id ? yup.string() : yup.string().required('Supplier is required')
  ),
  supplier_id: yup.string(),
  category_id: yup.string().required('Category is required'),
  kia_specification: yup.string().required('Supplier Specification is required'),
  unit_of_measurement: yup.string().required('Unit of Measurement is required'),
  quantity: yup.number().required('Quantity is required').positive().integer(),
  //supplier_price: yup.number().required('Supplier Price is required').positive(),
  //vendor_price: yup.number().required('Vendor Price is required').positive(),
  product_description: yup.string().required('Product Description is required'),
});

export const addRemarkSchema = yup.object().shape({
  remark: yup.string().required('Please provide a comment'),
});

export const updateProfileSchema = yup.object({
  first_name: yup.string()
  .matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters')
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  })
  .required('Please provide First Name '),

  last_name: yup.string()
  .matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters')
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  })
  .required('Please provide Last Name '),
  
  phone: yup.string().matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits").required("Please provide Phone Number"),
}).required();