import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, Typography } from "@mui/material";
import { useDispatch } from 'react-redux';
import { showToast } from '../../toast/toast';
import { getAllFeedback } from '../../store/home/getAllFeedback';
import { convertToDateFormatYearWithTwoDigits } from '../../utils/dateFormater';
import { truncateText } from '../reuseable/stringManipulation';
import Loader from '../reuseable/Loader';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function FeedbackReceived({formID}) {

    const dispatch = useDispatch();
    const [feedbacks, setFeedbacks] = useState([]);
    const [allFeedbacks, setAllFeedbacks] = useState([]);
    const [isActive, setIsActive] = useState('admin')
    const [loader, setLoader] = useState(false);
    const getFeedback = async () => {
        setLoader(true);
        const formD = {
            'form_id': formID
        }
        try {
            const response = await dispatch(getAllFeedback(formD));
            const data = response?.payload?.data || [];
            setAllFeedbacks(data);
            setFeedbacks(data.filter(feedback => (feedback?.added_by === 'admin') || (feedback?.added_by === 'dealer')));
            setLoader(false);
        } catch (error) {
            showToast(error, 2)
            setLoader(false);
        }
    }

    useEffect(() => {
        if(formID) {
            getFeedback();
        }
    }, [formID]);


    const filterFeedback = (type) => {
        return () => {
            setIsActive(type)
            setFeedbacks(allFeedbacks.filter(feedback => feedback?.added_by === type))
        }
    }
    if(loader) return <Loader/>
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Typography variant="h6" className="fs-17  fw-400">Feedback Received</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Box className="d-flex flex-wrap" sx={{ gap: "9px" }}>
                        <Button className= {isActive === 'admin' ? 'layout-blue-btn' : 'layout-lightblue-btn'}  onClick={filterFeedback('admin')}>
                            Feedback from KIA
                        </Button>
                        <Button className={isActive === 'innocean' ? 'layout-blue-btn' : 'layout-lightblue-btn'} onClick={filterFeedback('innocean')}>
                            Feedback to KIA
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                    <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{ background: "#85949F !important" }}>Feedback Received</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }}>Date</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }}>Added By</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }}>Feedback On</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }}>Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {feedbacks.map((row, index) => (
                                    <StyledTableRow
                                     key={index}
                                     >
                                        <StyledTableCell component="th" scope="row">
                                            {truncateText(row.comment, 20) || row.comment}
                                        </StyledTableCell>
                                        <StyledTableCell>{convertToDateFormatYearWithTwoDigits(row.created_at)}</StyledTableCell>
                                        <StyledTableCell>{row.added_by}</StyledTableCell>
                                        <StyledTableCell>{row.feedback_on}</StyledTableCell>
                                        <StyledTableCell>
                                            <Button className="DownloadBtn">View</Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                       
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
}