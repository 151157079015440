import EmailIcon from '@mui/icons-material/Email';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Avatar, Box, Button, Card, CardActionArea, IconButton, Modal, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import ModalCss from "../../styles/modal.module.scss";
import { Link } from 'react-router-dom';
import { forgetPasswordViaEmailApi } from '../../store/auth/forgetPasswordViaEmail';
import { authOtpViaEmailApi } from '../../store/auth/authOtpViaEmail';
import { useDispatch, useSelector } from 'react-redux';
import { authOtpViaSMSApi } from '../../store/auth/authOtpViaSMS';
import ResetViaEmailOTP from './ResetViaEmailOTP';
import closeLetterIconSvg from '../../assets/svg/closed-letter-svg.png'
import phoneIconSvg from '../../assets/svg/phone-icon-svg.png'
import { showToast } from '../../toast/toast';
export default function ForgetPassword({ open, setOpen, data }) {
  const forgetPasswordState = useSelector(state => state.forgetPasswordViaEmail);


  const [emailInputConf, setEmailInputConf] = useState(false)
  const [phoneVerifyModal, setPhoneVerifyModal] = useState(false)
  const [emailVerifyModal, setEmailVerifyModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null);
  const handleClose = () => setOpen(false)
  const dispatch = useDispatch();
  const loginState = useSelector(state => state.login);
  const user_type = "Innoceans"
  const email= localStorage.getItem("email")
  const loginSelector = useSelector((state) => state?.login);

  const handleSentOTPViaEmail = () => {
    setSelectedOption('email');
}

const handleSentOTPViaSMS = () => {   
    setSelectedOption('sms'); 
}

const handleSendLink = async() => {
    if (selectedOption === 'email') {
        const responseForgetPassword = await dispatch(forgetPasswordViaEmailApi({ type: "email", user_type, email: data?.email }));
      if(!responseForgetPassword?.payload?.error){
        localStorage.setItem('access_token' ,responseForgetPassword?.payload?.result?.access_token )
        //setEmailVerifyModal(true);
        const response = dispatch(authOtpViaEmailApi({ type: "email", user_type, email:data?.email, "forget_password" : "forget_password" }));
        if (!response?.payload?.error) {
          setOpen(false);
          setEmailVerifyModal(true);
          setSelectedOption(null);
        }else{
          showToast(response?.payload?.message, 2)
        }
      }else{
        showToast(responseForgetPassword?.payload?.message, 2)
      }
       
        
    } else if (selectedOption === 'sms') {
        dispatch(authOtpViaSMSApi());
        setPhoneVerifyModal(true);
    }
    
}

  return (
    <>
      <Modal open={open} onClose={handleClose} className={ModalCss?.customModal}>
        <Card className={`modal ${ModalCss?.customModal_md}`}>
        <Stack spacing={2} p={3} position={"relative"}>
            <Stack 
              alignItems="flex-end"
              position={"absolute"}
              right={5}
              top={5}><IconButton onClick={handleClose}><HighlightOffIcon /></IconButton></Stack>
            <Stack alignItems='center'>
              <Typography 
                className={ModalCss?.title}
                variant="h5"
                fontWeight={600}
              >Forget Password</Typography>
              <Typography  className={ModalCss?.subtitle} fontSize={16}>Please select an option to send OTP to reset password</Typography>
            </Stack>
            <Card 
              sx={{
                p: 0,
                marginTop: "30px !important",
                border: "1px solid rgba(171, 200, 221, 1)",
                boxShadow: "none",
              }}>
              <CardActionArea 
              // sx={{ px: 2, py: 3 }}
              style={{ padding: "16px", backgroundColor: selectedOption === 'email' ? '#A8A8A8' : '#fff' }}
               onClick={handleSentOTPViaEmail}>
                <Stack direction='row' alignItems='center' spacing={2}>
                  <Avatar sx={{ background: "rgba(235, 243, 248, 1)", p: 1 }}>
                  <img src={closeLetterIconSvg} alt="emailIcon" />
                  </Avatar>
                  <Box>
                    <Typography fontWeight={600} fontSize={18}  sx={{textTransform: "none"}}>Reset via Email</Typography>
                    <Typography fontSize={15}>An OTP will be sent to your registered email address</Typography>
                  </Box>
                </Stack>
              </CardActionArea>
            </Card>
            {/* <Card 
              sx={{
                p: 0,
                border: "1px solid rgba(171, 200, 221, 1)",
                boxShadow: "none",
              }}>
              <CardActionArea sx={{ px: 2, py: 3 }} onClick={handleSentOTPViaSMS}>
                <Stack direction='row' alignItems='center' spacing={3}>
                  <Avatar sx={{ background: "rgba(235, 243, 248, 1)", p: 1 }}>
                    <img src={phoneIconSvg} alt="phoneIcon" />
                  </Avatar>
                  <Box>
                    <Typography  fontWeight={600} fontSize={18}>Reset via SMS</Typography>
                    <Typography fontSize={15}>An OTP will be sent to your registered mobile number</Typography>
                  </Box>
                </Stack>
              </CardActionArea>
            </Card> */}
            <Box sx={{ textAlign: "center" }}>
              <Button 
                variant="contained"
                //handleSendLink
                onClick={handleSendLink}
                sx={{
                  marginTop: "20px !important",
                  width: "80%",
                  padding: { lg: "16px 10px", xs: "10px" },
                  fontSize: { lg: "18px", xs: "14px" },
                  fontWeight: { lg: "500", xs: "500" },
                  borderRadius: "10px",
                  textTransform: "none"
                }} >Send OTP</Button>
            </Box>
          </Stack>
        </Card>
      </Modal>
       <ResetViaEmailOTP open={emailVerifyModal} setOpen={setEmailVerifyModal} openPreviousModal={setOpen} />
    </>
  )
}
