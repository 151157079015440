import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Card, CircularProgress, Divider, FormControl, Grid, Menu, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import Body from '../../components/Body';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../toast/toast';
import { deleteInventoryApi, getInventoryApi } from '../../store/inventory';
import box from '../../assets/svg/box.svg';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ordersApi } from '../../store/orders/orders';
import '../OrderManagement/ordermanagement.scss';
import orderstatusimg from '../../assets/img/order-status-img.png'
const OrderStatus = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {loading:orderLoading, error:orderError, result:orderList}  = useSelector(state => state?.orders);
    
    const [allOrderData, setAllOrderData] = useState([]);
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const handleNavigate = (outletId) => {
        localStorage.setItem("selectedOutletIdToView", outletId);
        navigate(`/layout/view`);
    };

    const [editRowData, setEditRowData] = useState({});
    const editInventory = (editRowData) => {
        setEditRowData(editRowData)
        //open the pop with auto populated data
        setOpenInventoryModal(true)
    }

    const deleteInventory = (deleteRowId) => {
        const dataToDelete = { id: deleteRowId };
        dispatch(deleteInventoryApi(dataToDelete))
            .then(response => {
                if (response?.payload?.status) {
                    // setAllOrderData(prevData => prevData.filter(item => item.id !== deleteRowId));
                    showToast("Inventory deleted successfully", 1);
                    dispatch(getInventoryApi())
                } else {
                    showToast("Failed to delete inventory", 2);
                }
            })
            .catch(error => {
                showToast(error.message, 2);
            });
    };
    //Code for open inventory modal
    const [openInventoryModal, setOpenInventoryModal] = useState(false);
    const handleInventoryOpen = () => setOpenInventoryModal(true);
    const handleInventoryClose = () => setOpenInventoryModal(false);

    const viewOrder = (id) => {
        localStorage.setItem("selectedOrderIdView", id);
        navigate(`/order/view/`)
    }

    useEffect(() => {
        dispatch(ordersApi())
    },[dispatch])

    useEffect(() => {
        setAllOrderData(orderList?.data)   
    },[orderList])
    return (
        <Body>
            <Stack
                direction="row"
                spacing={10}
                sx={{ my: 2 }}
                justifyContent="space-between"
                alignItems={"center"}
            >
                <Stack direction="row" alignItems="center">
                    <img src={box} />
                    <Typography
                        color={"#05141F"}
                        fontSize={"17px"}
                        fontWeight={400}
                        marginInlineStart={"10px"}
                        fontFamily={"'KiaSignatureRegular' !important"}
                    >
                        View dispatch
                        status
                    </Typography>
                </Stack>
                {/* <Button variant="contained" onClick={()=>setOpenInventoryModal(true)}>Add Inventory</Button> */}
            </Stack>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='gap-10 flex-wrap'>
                        <Button className='layout-lightblue-btn'>All</Button>
                        <Button className='layout-blue-btn'>Delivered</Button>
                        <Button className='layout-lightblue-btn'>Intransit</Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='gap-20 flex-wrap'>
                        <Box className='status-card'>
                            <Box>
                                <img src={orderstatusimg}/>
                            </Box>
                            <Box className='d-flex justify-content-between mt-20'>
                                <Typography variant='h5' className='fs-15 fw-600 my-auto text-blue'>AKN23415</Typography>
                                <Box className='order-status'>
                                    <Typography variant='h5' className='fs-15 fw-600 my-auto'>Completed</Typography>
                                    <CheckCircleIcon/>
                                </Box>
                            </Box>
                            <Divider className='mt-10' />
                            <Box className='d-flex gap-10 mt-10'>
                                <TurnedInNotIcon/><Typography variant='h5' className='fs-13 fw-400 my-auto'>Amit KIA Noida</Typography>
                            </Box>
                            <Box className='d-flex gap-10 mt-10'>
                                <TurnedInNotIcon/><Typography variant='h5' className='fs-13 fw-400 my-auto'> Purpose : 2S Upgrade</Typography>
                            </Box>
                            <Box className='d-flex justify-content-between ViewDetail mt-10'>
                                <Typography variant='h5' className='fs-13 fw-400 my-auto'>₹ 1,79,000</Typography>
                                <Typography variant='h5' className='fs-11 fw-400 my-auto'>View Detail</Typography>
                            </Box>
                        </Box>
                        <Box className='status-card'>
                            <Box>
                                <img src={orderstatusimg}/>
                            </Box>
                            <Box className='d-flex justify-content-between mt-20'>
                                <Typography variant='h5' className='fs-15 fw-600 my-auto text-blue'>AKN23415</Typography>
                                <Box className='order-status'>
                                    <Typography variant='h5' className='fs-15 fw-600 my-auto'>Completed</Typography>
                                    <CheckCircleIcon/>
                                </Box>
                            </Box>
                            <Divider className='mt-10' />
                            <Box className='d-flex gap-10 mt-10'>
                                <TurnedInNotIcon/><Typography variant='h5' className='fs-13 fw-400 my-auto'>Amit KIA Noida</Typography>
                            </Box>
                            <Box className='d-flex gap-10 mt-10'>
                                <TurnedInNotIcon/><Typography variant='h5' className='fs-13 fw-400 my-auto'> Purpose : 2S Upgrade</Typography>
                            </Box>
                            <Box className='d-flex justify-content-between ViewDetail mt-10'>
                                <Typography variant='h5' className='fs-13 fw-400 my-auto'>₹ 1,79,000</Typography>
                                <Typography variant='h5' className='fs-11 fw-400 my-auto'>View Detail</Typography>
                            </Box>
                        </Box>
                        <Box className='status-card'>
                            <Box>
                                <img src={orderstatusimg}/>
                            </Box>
                            <Box className='d-flex justify-content-between mt-20'>
                                <Typography variant='h5' className='fs-15 fw-600 my-auto text-blue'>AKN23415</Typography>
                                <Box className='order-status'>
                                    <Typography variant='h5' className='fs-15 fw-600 my-auto'>Completed</Typography>
                                    <CheckCircleIcon/>
                                </Box>
                            </Box>
                            <Divider className='mt-10' />
                            <Box className='d-flex gap-10 mt-10'>
                                <TurnedInNotIcon/><Typography variant='h5' className='fs-13 fw-400 my-auto'>Amit KIA Noida</Typography>
                            </Box>
                            <Box className='d-flex gap-10 mt-10'>
                                <TurnedInNotIcon/><Typography variant='h5' className='fs-13 fw-400 my-auto'> Purpose : 2S Upgrade</Typography>
                            </Box>
                            <Box className='d-flex justify-content-between ViewDetail mt-10'>
                                <Typography variant='h5' className='fs-13 fw-400 my-auto'>₹ 1,79,000</Typography>
                                <Typography variant='h5' className='fs-11 fw-400 my-auto'>View Detail</Typography>
                            </Box>
                        </Box>
                        <Box className='status-card'>
                            <Box>
                                <img src={orderstatusimg}/>
                            </Box>
                            <Box className='d-flex justify-content-between mt-20'>
                                <Typography variant='h5' className='fs-15 fw-600 my-auto text-blue'>AKN23415</Typography>
                                <Box className='order-status'>
                                    <Typography variant='h5' className='fs-15 fw-600 my-auto'>Completed</Typography>
                                    <CheckCircleIcon/>
                                </Box>
                            </Box>
                            <Divider className='mt-10' />
                            <Box className='d-flex gap-10 mt-10'>
                                <TurnedInNotIcon/><Typography variant='h5' className='fs-13 fw-400 my-auto'>Amit KIA Noida</Typography>
                            </Box>
                            <Box className='d-flex gap-10 mt-10'>
                                <TurnedInNotIcon/><Typography variant='h5' className='fs-13 fw-400 my-auto'> Purpose : 2S Upgrade</Typography>
                            </Box>
                            <Box className='d-flex justify-content-between ViewDetail mt-10'>
                                <Typography variant='h5' className='fs-13 fw-400 my-auto'>₹ 1,79,000</Typography>
                                <Typography variant='h5' className='fs-11 fw-400 my-auto'>View Detail</Typography>
                            </Box>
                        </Box>
                        <Box className='status-card'>
                            <Box>
                                <img src={orderstatusimg}/>
                            </Box>
                            <Box className='d-flex justify-content-between mt-20'>
                                <Typography variant='h5' className='fs-15 fw-600 my-auto text-blue'>AKN23415</Typography>
                                <Box className='order-status'>
                                    <Typography variant='h5' className='fs-15 fw-600 my-auto'>Completed</Typography>
                                    <CheckCircleIcon/>
                                </Box>
                            </Box>
                            <Divider className='mt-10' />
                            <Box className='d-flex gap-10 mt-10'>
                                <TurnedInNotIcon/><Typography variant='h5' className='fs-13 fw-400 my-auto'>Amit KIA Noida</Typography>
                            </Box>
                            <Box className='d-flex gap-10 mt-10'>
                                <TurnedInNotIcon/><Typography variant='h5' className='fs-13 fw-400 my-auto'> Purpose : 2S Upgrade</Typography>
                            </Box>
                            <Box className='d-flex justify-content-between ViewDetail mt-10'>
                                <Typography variant='h5' className='fs-13 fw-400 my-auto'>₹ 1,79,000</Typography>
                                <Typography variant='h5' className='fs-11 fw-400 my-auto'>View Detail</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {/* <AddInventory open={openInventoryModal} handleClose={() => setOpenInventoryModal(false)} editRowData={editRowData}/> */}
        </Body>
        
    );
}
export default OrderStatus
