import React, { useEffect, useRef, useState } from "react";
import { Box, Button, FormHelperText, Grid, TextareaAutosize, Tooltip, Typography } from "@mui/material";
import { getGFCApi } from "../../store/home/getGFC";
import { useDispatch, useSelector } from "react-redux";
import { convertToFormData } from "../convertFormData";
import { showToast } from "../../toast/toast";
import { GFCSchema, cadUploadSchema, pdfUploadSchema } from "../validationSchemas";
import { postGFCApi } from "../../store/home/postGFC";
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { handleDownload } from "../reuseable/downloadFile";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import pdf from '../../assets/img/pdf.png'
import cad from '../../assets/img/cad.png'
import Loader from "../reuseable/Loader";

export default function Gfc({ formID }) {

    const dispatch = useDispatch();
    const {result : GFCData, loading : GFCLoading} = useSelector((state) => state?.getGFC);
    const {result : revisedGFCData} = useSelector((state) => state?.revisedGFCViewReducer);


    const [data, setData] = useState(localStorage.getItem("isRevisedGFCViewClick") == 1 ? revisedGFCData : GFCData);   


    useEffect(() => {
        const isRevisedGFCViewClick = localStorage.getItem("isRevisedGFCViewClick");
        setData(isRevisedGFCViewClick === '1' ? revisedGFCData : GFCData);
      }, [revisedGFCData, GFCData]);


    const [addGFCData ,  setAddGFCData] = useState();
    const [pdffileState, setPdfFileState] = useState([]);
    const [cadfileState, setCadFileState] = useState([]);
    const pdfRef = useRef(null);
    const cadRef = useRef(null);
    const moreAttachmentInputRef = useRef(null);
    const [moreAttachmentFileState, setMoreAttachmentFileState] = useState([]);
    const [errors, setErrors] = useState({});

    //Form starts here
    const handleSubmit = (e) => {
        e.preventDefault();
        GFCSchema.validate(addGFCData, { abortEarly: false })
            .then(() => {
                addRequestAPI();
                // if (inputDrawingByClusterId) {
                //   editRequestAPI()
                // } else {
                //   addRequestAPI();
                // }
                setErrors({});
            })
            .catch((validationErrors) => {
                const newErrors = {};
                validationErrors.inner.forEach((error) => {
                newErrors[error.path] = error.message;
                });
                setErrors(newErrors);
            });
    };


    const addRequestAPI = async () => {
        const formData = new FormData();
        formData.append("form_id", formID);
        
        //formData.append("previous_estimate", addSchematicLayout?.previous_estimate)
        formData.append("comment", addGFCData?.comment);
        if (pdffileState.length > 0) {
          pdffileState.forEach((file) => {
            formData.append("gfc_pdf", file);
    
          });
        }
        if (cadfileState.length > 0) {
          cadfileState.forEach((file) => {
            formData.append("gfc_cad", file);
          });
        }
        if (moreAttachmentFileState.length > 0) {
            moreAttachmentFileState.forEach((file) => {
            formData.append("more_attachments", file);
    
          });
        }
       

        try{
            const addGFCResponse = await dispatch(postGFCApi(formData));
            if (addGFCResponse?.error?.message) {
                showToast(addGFCResponse?.error?.message, 2);
                return;
            }else if (addGFCResponse?.payload?.status === "error") {
                showToast(addGFCResponse?.payload?.message, 2);
                return;
            }else{
                showToast(addGFCResponse?.payload?.message, 1)
                getGFC()
            }
        }catch(error){
            showToast(error?.message, 2)
        }       
        
       
    }

    const handleChange = (event) => {
        const value = event.target.value;
        setAddGFCData(prevState => ({
          ...prevState,
          [event.target.name]: value
        }));
    };

    const handlePdfUpload = () => {
        pdfRef.current.click();
    };
    
    const handleCadUpload = () => {
        cadRef.current.click();
    };
    
    const handlePdfFileUpload = (event) => {
        const files = event.target.files;
        const isValid = pdfUploadSchema.isValidSync({ pdfFile: files });
        if (isValid===true) {
            setErrors({ ...errors, pdfFile: "" });
            const pdfFiles = Array.from(event.target.files);
            setAddGFCData(prevState => ({
              ...prevState,
              pdfFile: pdfFiles
            }))
            setPdfFileState(prevFile => [...prevFile, ...pdfFiles]);
        } else {
            setErrors({ ...errors, pdfFile: "PDF file is required" });
            
        }
    };
    const handleRemoveFile = (index, type) => {
        let updatedFiles;
    
        switch (type) {
            case "pdfFile":
                updatedFiles = pdffileState.filter((_, i) => i !== index);
                setPdfFileState(updatedFiles);
                break;
            case "cadFile":
                updatedFiles = cadfileState.filter((_, i) => i !== index);
                setCadFileState(updatedFiles);
                break;
            case "moreAttachmentFile":
                updatedFiles = moreAttachmentFileState.filter((_, i) => i !== index);
                setMoreAttachmentFileState(updatedFiles);
                break;
            default:
                console.error("Unknown file type");
        }
    };
    
    
    
    const handleCadFileUpload = (event) => {
        const files = Array.from(event.target.files);
        const dwgFiles = files.filter(file => file.name.toLowerCase().endsWith('.dwg'));
    
        if (dwgFiles.length === files.length) {
            // All files have .dwg extension
            const isValid = cadUploadSchema.isValidSync({ cadFile: files });
            if (isValid) {
                setErrors({ ...errors, cadFile: "" });
                setAddGFCData(prevState => ({
                    ...prevState,
                    cadFile: dwgFiles
                }));
                setCadFileState(prevFile => [...prevFile, ...dwgFiles]);
                // Proceed with the file upload
            } else {
                setErrors({ ...errors, cadFile: "Cad file is required" });
            }
        } else {
            setErrors({ ...errors, cadFile: "Only DWG files are allowed" });
        }
    };
    


    const handleMoreAttachmentFileUpload = () => {
        moreAttachmentInputRef.current.click();
    };

    const handleFileChangeAttachement = (event) => {
        const attachFiles = Array.from(event.target.files);
        setMoreAttachmentFileState(prevFile => [...prevFile, ...attachFiles])
        
    };
    //Ends here

    const getGFC = async () => {
        const formD = {
            'form_id': formID
        }
        const dataToSubmit = convertToFormData(formD);
        try {
            const response = await dispatch(getGFCApi(dataToSubmit));
            //setData(response?.payload?.data);
        } catch (error) {
            showToast(error, 2)
        }
    }


    const resetForm = () => {
        setAddGFCData({
            'comment': '',
            'gfc_pdf': '',
            'gfc_cad': '',
            'more_attachments': '',
        })
        setPdfFileState([]);
        setCadFileState([]);
        setMoreAttachmentFileState([]);
        setErrors({});
    }

    const [ isRevisedRequest, setIsRevisedRequest ] = useState(false);
    const handleReset = () => {
        resetForm();  
        setIsRevisedRequest(true)
    }

    useEffect(() => {
        if(formID) {
            getGFC();
        }
    }, [formID]);

    if(GFCLoading){
        return <Loader />
    }
    return (
        <>
            {!GFCLoading && (data.length===0 || isRevisedRequest) && (
                
                <form
                    onSubmit={handleSubmit}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>

                        <Grid container spacing={2} className="m-auto w-100" sx={{ background: "#F9FCFF", padding: "28px 26px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: "0px !important" }}>
                                <Typography variant="h6" className="fs-17 ">Upload GFC</Typography>
                                <Box className="d-flex flex-wrap" sx={{ gap: "24px" }}>
                                    <Box sx={{ maxWidth: "213px",width:"100%" }}>

                                        <Box className="upload-box text-center">
                                            <Box className="m-auto">
                                                <input
                                                    type="file"
                                                    ref={pdfRef}
                                                    style={{ display: "none" }}
                                                    onChange={handlePdfFileUpload}
                                                    name="pdfFile"
                                                    multiple
                                                />
                                                
                                                <Button onClick={handlePdfUpload}>
                                                    <FileUploadOutlinedIcon sx={{fontSize:"40px"}} ></FileUploadOutlinedIcon>
                                                </Button>
                                                
                                                <Typography variant="h6" className="fs-14 ">Upload Design Plan</Typography>
                                                
                                                <span className="fs-12 ">(pdf File)</span>

                                            <FormHelperText error>
                                                {errors?.pdfFile}
                                            </FormHelperText>
                                            </Box>
                                            
                                        </Box>
                                        <Box sx={{height:"fit-content"}}>
                                        {pdffileState.length > 0 ? (
                                            pdffileState.map((file, index) => (
                                                <Tooltip title={file.name} placement="top"  className={` uploadedfilename`} >
                                                    <Typography key={index} >
                                                    {file.name}
                                                    </Typography>
                                                    <CancelIcon onClick={() => handleRemoveFile(index, "pdfFile")} />
                                                </Tooltip>
                                            ))
                                        ) : ""}
                                        </Box>
                                    </Box>
                                    <Box sx={{ maxWidth: "213px",width:"100%" }}>

                                        <Box className="upload-box text-center">
                                            <Box className="m-auto">
                                            <input
                                                type="file"
                                                ref={cadRef}
                                                style={{ display: "none" }}
                                                onChange={handleCadFileUpload}
                                                name="cadFile"
                                                multiple
                                            />
                                            <Button onClick={handleCadUpload}>
                                                <FileUploadOutlinedIcon sx={{fontSize:"40px"}} ></FileUploadOutlinedIcon>

                                            </Button>
                                            <Typography variant="h6" className="fs-14 ">Upload Design Plan</Typography>

                                            <span className="fs-12 ">(cad File)</span>

                                            <FormHelperText error>
                                                {errors?.cadFile}
                                            </FormHelperText>
                                            </Box>

                                        </Box>
                                        <Box sx={{height:"fit-content"}}>
                                        {cadfileState.length > 0 ? (
                                                cadfileState.map((file, index) => (
                                                    <Tooltip title={file.name} placement="top"  className={` uploadedfilename`} >
                                                        <Typography key={index} >
                                                        {file.name}
                                                        </Typography>
                                                        <CancelIcon onClick={() => handleRemoveFile(index, "cadFile")} />
                                                    </Tooltip>
                                                    ))
                                                ) : ""}
                                        </Box>
                                    </Box>
                                    
                                </Box>

                                

                                <Typography variant="h6" className="fs-17 " sx={{ marginTop: "50px" }}>Comment</Typography>
                                <TextareaAutosize
                                error={Boolean(errors.comment)}
                                helperText={errors.comment}
                                value={addGFCData?.comment}
                                onChange={handleChange}
                                id="input"
                                variant="outlined"
                                fullWidth
                                name="comment"                                        
                                style={{ maxWidth: "500px", width: "100%", border: "1px solid #C4D3DF", borderRadius: "5px", padding: "10px" }} 
                                className="bg-white" 
                                minRows={6} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="m-auto w-100" sx={{ padding: "28px 26px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: "0px !important", gap: "22px" }}>
                                <Button className="black-btn" type="submit">
                                Send TO KIA
                                </Button>
                                <Button className="link-btn" sx={{ marginLeft: "22px" }} onClick={() =>resetForm()}>
                                Reset
                                </Button>
                            </Grid>
                        </Grid>
                 </Grid>
            </form>                    
        )}
            {!GFCLoading && (data?.id>0 && isRevisedRequest===false) && (
                <Box className="h-100 d-flex flex-column justify-content-between">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                    <Typography variant="h6" className="fs-17  fw-400">GFC Received</Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                    <Box className="d-flex flex-wrap w-100" sx={{gap:"24px"}}>
                        <Box sx={{width:"213px"}}>
                            {data?.gfc_pdf?.map((data) => (
                                <>
                                    <Box className="upload-box bg-white text-center">
                                        <Box className="m-auto p-relative">
                                            <Tooltip title={data.files} placement="top" className={` uploadedfilename`} >
                                                <img width={40} height={40} src={pdf} className="my-auto" />
                                                <Typography>
                                                    {'PDF File'}
                                                </Typography>
                                                <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_cad', data.files)} />
                                                <FullscreenIcon className="my-auto" onClick={() => window.open(`https://kiastageapi.dev.vinove.com${data.files}`)} />
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </>
                            ))}
                        </Box>
                        <Box sx={{width:"213px"}}>
                            {data?.gfc_cad?.map((data) => (
                                <>
                                    <Box className="upload-box bg-white text-center">
                                        <Box className="m-auto p-relative">
                                            <Tooltip title={data.files} placement="top" className={` uploadedfilename`} >
                                                <img width={40} height={40} src={cad} className="my-auto" />
                                                <Typography>
                                                    {'CAD File'}
                                                </Typography>
                                                <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_cad', data.files)} />
                                                <FullscreenIcon className="my-auto" onClick={() => window.open(`https://kiastageapi.dev.vinove.com${data.files}`)} />
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </>
                            ))}
                        </Box>
                        <Box className="w-100">
                        <Typography variant="h6" className="fs-17 " sx={{ marginTop: "50px" }}>Comment</Typography>
                                
                                <TextareaAutosize
                                error={Boolean(errors.comment)}
                                helperText={errors.comment}
                                value={data?.comment}
                                onChange={handleChange}
                                disabled
                                id="input"
                                variant="outlined"
                                fullWidth
                                name="comment"                                        
                                style={{ maxWidth: "500px", width: "100%", border: "1px solid #C4D3DF", borderRadius: "5px", padding: "10px" }} 
                                className="bg-white" 
                                minRows={6} />
                        </Box>
                        
                    </Box>
                </Grid>

            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex" sx={{gap:"23px"}}>
                    <Button className="white-btn" onClick={() => handleReset()}>Reset</Button>
                </Grid>
            </Grid>
        </Box>
            )}
            
        </>
        
    );
}