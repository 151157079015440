import React from "react";
import { Box, Button, Grid, OutlinedInput, Typography, TextareaAutosize, Tooltip, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { approveInputDrawingApi, getInputDrawingByClusterIdApi } from "../../store/home/getInputDrawing";
import { convertToFormData } from "../convertFormData";
import Feedback from "../reuseable/Feedback";
import { showToast } from "../../toast/toast";
import OpenWithIcon from '@mui/icons-material/OpenWith';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { handleDownload } from "../reuseable/downloadFile";
import DoneIcon from '@mui/icons-material/Done';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import pdf from '../../assets/img/pdf.png'
import cad from '../../assets/img/cad.png'
import Loader from "../reuseable/Loader";

export default function InputDrawing({ dealorOutletId }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);  // State for modal visibility
    const handleOpen = (id) => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = useState(false)
    const getInputDrawing = async () => {
        setLoading(true)
        const formD = {
            'dealor_outlet_id': dealorOutletId
        }
        const data = convertToFormData(formD);
        try {
            const response = await dispatch(getInputDrawingByClusterIdApi(data));
            setData(response?.payload?.data);
            setLoading(false);
        } catch (error) {
            showToast(error, 2)
            setLoading(false);
        }
    }

    useEffect(() => {
        if (dealorOutletId) {
            getInputDrawing();   
        }
    }, [dealorOutletId]);

    const [approveLoading, setApproveLoading] = useState(false);
    const approveInputDrawing = async () => {
       const formData = convertToFormData({ 'form_id': data.id });

        try {
            const response = await dispatch(approveInputDrawingApi(formData));
            showToast(response?.payload?.message, 1);
            setApproveLoading(false)
            getInputDrawing()
            
        } catch (error) {
            console.error("Error in sending data to KIA:", error);
        }
    };
    if (loading) {
        return <Loader/>;
    }

    return (
        <>
            <Box className="h-100 d-flex flex-column justify-content-between">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                        <Typography variant="h6" className="fs-17  fw-400">Input Drawing</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                        <Box className="d-flex flex-wrap w-100" sx={{ gap: "24px" }}>
                            <Box sx={{ width: "213px" }}>
                                {data?.input_pdf?.map((schematicLayoutData) => (
                                    <>
                                        <Box className="upload-box bg-white text-center">
                                            <Box className="w-100 p-relative">
                                                <Tooltip title={schematicLayoutData.files} placement="top" className={` uploadedfilename`} >
                                                    <img width={40} height={40} src={pdf} className="my-auto" />
                                                    <Typography>
                                                        {'PDF File'}
                                                    </Typography>
                                                    <Box className="d-flex flex-nowrap gap-5 ml-auto">
                                                    <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)} />
                                                    <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)} />
                                                    </Box>
                                                    
                                                </Tooltip>
                                            </Box>
                                        </Box>

                                    </>
                                ))}
                            </Box>
                            <Box sx={{ width: "213px" }}>
                                {data?.input_cad?.map((schematicLayoutData) => (
                                    <>
                                        <Box className="upload-box bg-white text-center">
                                            <Box className="m-auto p-relative">
                                                <Tooltip title={schematicLayoutData.files} placement="top" className={` uploadedfilename`} >
                                                    <img width={40} height={40} src={cad} className="my-auto" />
                                                    <Typography>
                                                        {'CAD File'}
                                                    </Typography>
                                                    <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)} />
                                                    <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)} />
                                                </Tooltip>
                                            </Box>
                                        </Box>

                                    </>
                                ))}
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Grid container spacing={2} sx={{ marginTop: "50px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="h6" className="fs-17 ">Sections Heights (in Ft.):</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" className="fs-13 ">Road to Start of Building</Typography>
                                <OutlinedInput disabled className="w-100 cstm-inputfield" placeholder={data?.distance_from_road} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" className="fs-13 ">Building Total Height/ground level to Roof</Typography>
                                <OutlinedInput disabled className="w-100 cstm-inputfield" placeholder={data?.building_height} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" className="fs-13 ">Ceiling Height</Typography>
                                <OutlinedInput disabled className="w-100 cstm-inputfield" placeholder={data?.ceiling_height} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" className="fs-13 ">Beam Height</Typography>
                                <OutlinedInput disabled className="w-100 cstm-inputfield" placeholder={data?.beam_height} />
                            </Grid>
                        </Grid>

                        <Typography variant="h6" className="fs-17 " sx={{ marginTop: "50px" }}>Attach Photos (Long Shot & Clear Image Required):</Typography>
                            <Typography variant="h6" className="fs-13 mb-10" sx={{ marginTop: "22px" }}>Interior Photos</Typography>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                            <Box className="d-flex flex-wrap w-100" sx={{ gap: "24px" }}>
                                {data?.layout_interior?.map((interiorPhotos) => {
                                    return (
                                        <Box sx={{ width: "213px" }}>
                                            <img className="border-radious-14" style={{ width: "198px", height: '181px' }}
                                                src={`${process.env.REACT_APP_BASE_URL}${interiorPhotos?.photos}`}
                                            />
                                            <Box className="text-center justify-center d-flex" sx={{ gap: "7px", marginTop: "15px" }}>
                                                <Box className="my-auto">
                                                    <OpenWithIcon fontSize="small"></OpenWithIcon>
                                                </Box>
                                                <Button className="fs-12 link-btn" sx={{ fontSize: "12px !important" }} onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${interiorPhotos?.photos}`)}>Full View</Button>
                                            </Box>
                                        </Box>
                                    )
                                }
                                )}
                            </Box>
                        </Grid>
                                <Typography variant="h6" className="fs-13 mb-10" sx={{ marginTop: "22px" }}>Exterior Photos</Typography>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                            <Box className="d-flex flex-wrap w-100" sx={{ gap: "24px" }}>
                                {data?.layout_exterior?.map((exteriorPhotos, index) => {
                                    return (
                                        <Box sx={{ width: "213px" }} key={index}>
                                            <img className="border-radious-14" style={{ width: "198px", height: '181px' }}
                                                    src={`${process.env.REACT_APP_BASE_URL}${exteriorPhotos?.photos}`}
                                                />
                                            <Box className="text-center justify-center d-flex" sx={{ gap: "7px", marginTop: "15px" }}>
                                                <Box className="my-auto">
                                                    <OpenWithIcon fontSize="small"></OpenWithIcon>
                                                </Box>
                                                <Button className="fs-12 link-btn" sx={{ fontSize: "12px !important" }} onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${exteriorPhotos?.photos}`)}>Full View</Button>
                                            </Box>
                                        </Box>
                                    )}
                                )}
                            </Box>
                        </Grid>
                        
                        <Typography variant="h6" className="fs-17 " sx={{ marginTop: "50px" }}>Dealer's Comment</Typography>
                        <TextareaAutosize disabled style={{ maxWidth: "500px", width: "100%", border: "1px solid #C4D3DF", borderRadius: "5px", padding: "10px" }} className="bg-white" minRows={6} placeholder={data?.comment} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex" sx={{ gap: "12px",marginTop: "30px" }}>
                    
                        {data?.approve_by_innoceans ? 
                            <Button className="black-btn" style={{color: "green", textTransform: "none"}}><DoneIcon style={{ marginRight: "2px", marginTop: "-5px" }}/> Approved</Button>
                        :   
                            <Button  disabled={approveLoading} variant="contained" type="submit"  size="large" sx={{ fontSize: { lg: "20px", md: "16px", xs: "14px" },textTransform: "none"}} className="black-btn" onClick={() => approveInputDrawing()}>
                            {approveLoading ? <CircularProgress color="inherit" /> : "Approve Input Drawing"}
                            </Button>
                        }
                        <Button className="white-btn" onClick={() => handleOpen()} style={{color: "green", textTransform: "none"}}>Send Feedback To KIA</Button>
                    </Grid>
                </Grid>

            </Box>
            <Feedback open={open} handleClose={handleClose} id={data?.id} userType="innocean" feedBackOn="Input Drawing" />
        </>
    );
}